import ko from 'knockout';
import resourceHelper from '../Utils/resourceHelper';

/**
 * Binding to display localised text based on the data in the server-side resource files
 * @example
 * <h2 data-bind="restext: 'Registration'"></h2>
 */
export const restext = {
  update: function (element, valueAccessor, allBindingsAccessor, viewModel, context) {

    const text = getLocalizedString(ko.utils.unwrapObservable(valueAccessor()));

    ko.bindingHandlers.text.update(
        element,
        function () {
          return text;
        },
        allBindingsAccessor,
        viewModel,
        context);
  }
};

function getLocalizedString(binding) {

  let value;
  let replaceKey;

  // Allow both restext: 'Home' as string and restext: { key: 'Home' } as an object
  if (typeof binding === 'string' || binding instanceof String) {
    binding = { key: binding };
  }

  value = resourceHelper.getString(binding.key);

  if (binding.params) {

    for (replaceKey in binding.params) {
      if (!Object.prototype.hasOwnProperty.call(binding.params, replaceKey)) {
        continue;
      }

      let replacement = binding.params[replaceKey];

      if (typeof replacement === 'function') {
        replacement = ko.utils.unwrapObservable(replacement());
      }

      value = value.replace('{' + replaceKey + '}', replacement);
    }
  }

  return value;
}

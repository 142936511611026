import $ from 'jquery';
import ko from 'knockout';
import logger from '@/Utils/logger';

/**
 * Binding to apply to a input of type upload which listens to the input change event and calls the
 * provided function when one or more files have been selected.
 */
export const fileInput = {
  init: function (element, valueAccessor) {

    const addFileUploadsCallback = valueAccessor();

    if (!$(element).is('input')) {
      logger.error('UnhandledError', 'The file input binding can only be applied to a input tag.');
    }

    if (typeof addFileUploadsCallback !== 'function') {
      logger.error('UnhandledError', 'The value provided is not a function / callback.');
    }

    $(element).on('change', function (event) {

      const element = event.target;

      // Allow for browsers that handle the value reset / empty string as a change
      if (element.value === '') {
        return;
      }

      const fileList = element.files;
      Array.from(fileList)
          .forEach(file => {
            Object.defineProperty(file, 'isFile', {
              value: true
            });
          });
      addFileUploadsCallback(fileList);

      // Set to empty string to reset and allow same file to be selected again
      element.value = '';
    });

    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      $(element).off('change');
    });
  }
};

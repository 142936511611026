import $ from 'jquery';
import ko from 'knockout';

// Binding to show an overlay div on top of an element to render the element disabled
export const disableElement = {

  update: function (element, valueAccessor) {

    const value = ko.utils.unwrapObservable(valueAccessor());
    let overlayElement;

    if (value) {

      // Ensure the overlay div contains some content to ensure it's rendered in IE
      overlayElement = $('<div class=\'disable-element-overlay\'>&nbsp</div>');
      $(element).css('position', 'relative');
      $(element).append(overlayElement);
    } else {
      $(element).find('.disable-element-overlay').remove();
    }
  }
};

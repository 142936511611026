import ko from 'knockout';

export const dynamicForm = {
  init: function (element, valueAccessor, allBindings, viewModel) {

    const components = valueAccessor();
    const formData = allBindings.get('data');
    const isReadOnly = allBindings.get('isReadOnly');
    const isViewAsHtml = allBindings.get('isViewAsHtml');
    const language = allBindings.get('language');
    const i18n = allBindings.get('i18n');

    const dataChangedCallback = allBindings.get('dataChangedCallback');

    import(/* webpackChunkName: "dynamic-forms" */ '@publicsafety/dynamic-forms') // Dynamically import large Dynamic Form dependencies
        .then(dynamicFormModule => {
          const dynamicForm = dynamicFormModule.default;

          dynamicForm
              .renderForm({
                containerElement: element,
                components: components ? ko.unwrap(components) : [],
                data: formData ? ko.unwrap(formData) : {},
                isReadOnly: isReadOnly ? ko.unwrap(isReadOnly) : false,
                isViewAsHtml: isViewAsHtml ? ko.unwrap(isViewAsHtml) : false,
                language: language,
                i18n: i18n,
                dataChangedCallback: typeof dataChangedCallback === 'function' ? dataChangedCallback : function () { }
              })
              .then(function (dynamicForm) {
                viewModel.dynamicForm = dynamicForm;
              });
        });
  }
};

import $ from 'jquery';
import ko from 'knockout';
import resourceHelper from '../Utils/resourceHelper';

// All available options can be viewed here: http://getbootstrap.com/javascript/#tooltips-options
const defaultOptions = {
  container: 'body',
  placement: 'top',
  delay: 0,
  trigger: 'hover focus'
};

/**
 * Binding to display a tooltip from content in the resource files
 * @example
 * <span data-bind="tooltip: 'Tooltip content', placement: 'right'">Hello</span>
 */
export const restooltip = {

  init: function (element, valueAccessor, allBindings) {

    const text = resourceHelper.getString(valueAccessor());
    bindToElement(element, allBindings, text);
  }
};

/**
 * Binding to display a tooltip with text already translated
 * @example
 * <span data-bind="tooltip: 'Tooltip content', placement: 'right'">Hello</span>
 */
export const tooltip = {

  update: function (element, valueAccessor, allBindings) {

    const text = ko.utils.unwrapObservable(valueAccessor());
    bindToElement(element, allBindings, text);
  }
};

function bindToElement(element, allBindings, text) {

  const tooltipInitialized = $(element).data && $(element).data('bs.tooltip');

  if (tooltipInitialized) {
    // When the tooltip is already initialized, just update the title
    $(element).attr('data-original-title', text);
    return;
  }

  const options = {
    title: text,
    container: allBindings.get('container') || defaultOptions.container,
    placement: allBindings.get('placement') || defaultOptions.placement,
    trigger: allBindings.get('trigger') || defaultOptions.trigger
  };

  if (allBindings.get('delay')) {
    options.delay = { show: allBindings.get('delay'), hide: 0 };
  }

  $(element).tooltip(options);

  ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
    $(element).tooltip('destroy');
  });
}

import ko from 'knockout';
import logger from '../Utils/logger';

/**
 * Binding that causes the click event to be triggered on another element when the bound element has
 * the "enter" or "space" key pressed while it has focus.
 *
 * An example use is a fake button for file uploads that when the "enter" or "space" key is pressed
 * then triggers the file selection / an input click.
 *
 * <span data-bind="enterTriggersElementClick: 'fileUploadInput'" class="btn btn-primary" role="button" tabindex="0">Upload</span>
 * <input id="fileUploadInput" type="file" name="files[]" multiple="multiple" />
 */
export const enterTriggersElementClick = {

  init: function (element, valueAccessor) {

    const targetElementId = ko.utils.unwrapObservable(valueAccessor());
    const targetElement = document.getElementById(targetElementId);

    if (!targetElement) {
      logger.error('UnhandledError', 'An element with the Id ' + targetElementId + ' was not found.');
      return;
    }

    if (!targetElement.click) {
      logger.error(
          'UnhandledError',
          'The element with the Id ' + targetElementId + 'does not contain a click property.');
      return;
    }

    function clickHandler(event) {

      const keyCode = (event.which ? event.which : event.keyCode);

      if (keyCode === 13 || keyCode === 32) { // Enter (13) and the space (32) keys
        event.preventDefault(); // Pressing the "space" key, if propagated will cause some browsers to scroll down
        targetElement.click();
      }
    }

    element.addEventListener('keypress', clickHandler);

    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      element.removeEventListener('keypress', clickHandler);
    });

  }
};

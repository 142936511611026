import $ from 'jquery';
import ko from 'knockout';

export const showModal = {

  init: function (element, valueAccessor, allBindings) {

    const observable = valueAccessor();
    const afterShowCallback = allBindings.get('afterShowCallback');

    $(element).on('shown.bs.modal', function () {
      observable(true);

      if (typeof afterShowCallback === 'function') {
        afterShowCallback();
      }

    });

    $(element).on('hidden.bs.modal', function () {
      observable(false);
    });

    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      $(element).off('shown.bs.modal');
      $(element).off('hidden.bs.modal');
    });
  },
  update: function (element, valueAccessor, allBindings) {

    const value = ko.utils.unwrapObservable(valueAccessor());
    const closeImmediately = allBindings.get('closeImmediately');

    if (value) {
      $(element).modal('show');
      return;
    }

    // If the close immediately option is true and the fade effect is enabled then disable the fade effect
    // while the modal is being closed.
    if (closeImmediately && $(element).hasClass('fade')) {
      $(element).removeClass('fade');
      $(element).modal('hide');
      $(element).addClass('fade');
    }

    $(element).modal('hide');
  }
};

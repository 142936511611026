import $ from 'jquery';
import uploadManager from '@/Utils/uploadManager';
import FlowWrapper from '@/Utils/flowWrapper';


/**
 * Binding to create an instance of the jQuery File Upload functionality against a DOM element to
 * support uploading of files in chunks. This binding uses the "fileupload" module to observe the file
 * uploads array for changes as well as to update the status and progess of uploads.
 */
export const uploadEngine = {
  init: function (element) {

    FlowWrapper.init();
    uploadManager.uploads.subscribe(function (changes) {

      $.each(changes, function (index, change) {

        const upload = change.value;

        if (change.status === 'added') {
          FlowWrapper.addFileUpload(upload);
        }
      });

    }, null, 'arrayChange');

    // Prevent the browser default drag and drop behaviour so if a file is dragged onto the web page but
    // outside of a drop zone then the file browser doesn't load the local file.
    $(document).on('dragover', function (e) {
      e.preventDefault();
    });

    $(document).on('drop', function (e) {
      e.preventDefault();
    });

  }
};
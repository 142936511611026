import * as ko from 'knockout';
import { AllBindings, BindingHandler, Observable } from 'knockout';
import logger from '../Utils/logger';

/**
 * Binding to display "aria-invalid" attribute for fields under control of knockout validation rules
 * The validated field has IsValid method which allows dynamic resolving of aria-invalid attribute value
 * The validated field has isModified method which allows tweaking the logic for just opened forms
 * @example
 * <input id="givenName" data-bind="textInput: givenName, resAriaInvalid: givenName"></>
 * ko automatically removes falsy attributes, see https://github.com/knockout/knockout/blob/master/spec/defaultBindings/attrBehaviors.js#L74-84
 * @param {HTMLElement} element HTMLElement
 * @param {Observable} valueAccessor field to watch to be valid
 * @param {AllBindings} allBindings ko AllBindings
 * @param {any} viewModel View model the binding is applied on
 */
const ariaInvalidCallback = (
  element: HTMLElement,
  valueAccessor: () => Observable,
  allBindings: AllBindings,
  viewModel: any): void => {
  if (valueAccessor().isValid === undefined) {
    logger.warning('UnhandledError', 'Aria invalid binding should be in use only for an Observable with validation rules');
    return;
  }
  // ko.validation by default does not message error if the observable is not modified
  // the code follows the same logic
  const isModified = valueAccessor().isModified && valueAccessor().isModified();
  const isValid = !isModified || valueAccessor().isValid();

  ko.applyBindingsToNode(element, { attr: { 'aria-invalid': !isValid } }, viewModel);
};

const bindingHandler: BindingHandler = {
  init: ariaInvalidCallback,
  update: ariaInvalidCallback
};

export const ariaInvalid = bindingHandler;
